@import "../../styles/variables";

.select {
  position: relative;
  margin-left: 21px;
  
  &__dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 48px;
    font-size: 18px;
    background-color: $light-grey-color;
    outline: none;
    cursor: pointer;
  }

  &__dropdownList {
    display: block;
    position: absolute;
    width: 100%;
    max-height: 242px;
    font-size: 13px;
    border: 1px solid $light-grey-color;
    background-color: white;
    z-index: 2;
    opacity: 0;
    transition: opacity $transition-duration;
    box-sizing: border-box;
    pointer-events: none;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: $light-grey-color;
      height: 20px;
    }

    &_active {
      opacity: 1;
      pointer-events: initial;
    }
  }

  &__dropdownItem {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 7px 5px;
    transition: background-color $transition-duration;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      background-color: $secondary-color;
    }
  }
}
.checkbox {
  display: none;
}

.img {
  margin-right: 11px;
}

.label {
  display: flex;
  align-items: center;
  margin-right: 20px;
}


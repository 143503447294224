@import "../../styles/variables";

.questions {
  &__info {
    display: flex;
    cursor: pointer;
  }

  &__infoItem {
    display: flex;
    align-items: center;
    max-width: 200px;
    width: 100%;
    height: 58px;
  }

  &__infoItem:first-child {
    margin-left: 65px;
  }

  &__approveButton,
  &__notApproveButton {
    width: 29px;
    height: 29px;
    padding: 0;
    border: none;
    background: none;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.8;
    transition: opacity $transition-duration;

    &:hover {
      opacity: 1;
    }

    &:disabled {
      opacity: 0.3;
    }
  }

  &__approveButton {
    margin-right: 12px;
    background-image: url(../../img/icons/approve-button.svg);
  }

  &__notApproveButton {
    background-image: url(../../img/icons/not-approve-button.svg);
  }
}

@import "src/styles/variables";

.login {
  display: flex;
  min-height: 100vh;

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50%;
    background-color: $medium-grey-color;
  }

  &__img {
    width: 130px;
    height: 95px;
  }

  &__form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
}

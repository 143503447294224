.title {
  font-size: 18px;
  margin: 7px 0;
}

.questionWrapper {
  margin-bottom: 30px;
}

.answer {
  display: flex;
  align-items: center;
  width: 100%;
}

.audioContainer {
  display: flex;
  align-items: center;
  height: 100%;
  width: 500px;
  border-radius: 10px;
}

.previewContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 35px;
  background-color: #EDEDED;
  box-sizing: border-box;
  width: 100%;
}

.ratingContainer {
  margin-bottom: 30px;
}

.preview {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.questionContainer {
  display: flex;
  align-items: center;
}

.questionTitleContainer {
  display: flex;
  align-items: center;
  width: 70%;
  justify-content: space-between;
}

.solutionAnswer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  padding: 14px;
  min-height: 50px;
  min-width: 50px;
  box-sizing: border-box;
  background-color: #fff;
}

.btnContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 30px;

  .cancelButton {
    width: 257px;
    height: 63px;
    border: none;
    outline: none;
    background-color: #C4C4C4;
    font-size: 24px;
  }

  .confirmButton {
    width: 257px;
    height: 63px;
    border: none;
    outline: none;
    background-color: #272727;
    color: #fff;
    font-size: 24px;
  }
}

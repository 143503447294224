@font-face {
  font-family: "Grotesk";
  src: url(../fonts/HKGrotesk-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: "Grotesk";
  src: url(../fonts/HKGrotesk-Bold.ttf);
  font-weight: 700;
}

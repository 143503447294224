.emptyImgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #C4C4C4;
  border-radius: 10px;
  height: 100%;
  width: 200px;
}

.answerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}

.title {
  font-size: 18px;
  margin-bottom: 7px;
}

.questionWrapper {
  margin-bottom: 30px;
}

.answer {
  display: flex;
  align-items: center;
  width: 100%;
}

.fieldsContainer {
  display: flex;
  height: 200px;
  flex-direction: column;
  flex-wrap: wrap;
}

.buttonSpace {
  margin-top: 8px;
}

.answerWrapper {
  margin-bottom: 30px;
}

.pictureContainer {
  height: 200px;
  width: 200px;
  border-radius: 10px;
}

.previewQuestionContainer {
  margin-bottom: 20px;
  color: #00000090;
  text-transform: uppercase;
  font-size: 14px;
}

.previewContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 25px 35px;
  background-color: #EDEDED;
  box-sizing: border-box;
  width: 100%;

  .previewQuestion {
    width: 100%;
    padding-right: 30px;
  }

  .previewAnswer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 12px 25px;
    min-height: 44px;
    min-width: 77px;
    box-sizing: border-box;
  }
}

.preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}

.previewAnswerWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.previewAnswerContainer {
  padding: 13px;
  min-height: 44px;
  width: 230px;
  margin: 7px;
  box-sizing: border-box;
  background-color: #fff;
  text-align: center;
}

.additionalSettings {
  display: flex;
  margin-bottom: 25px;
}

.formContainer {
  display: flex;
  min-height: calc(100% - 71px);
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.answerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}

.title {
  font-size: 18px;
  margin-bottom: 7px;
}

.questionWrapper {
  margin-bottom: 30px;
}

.answer {
  display: flex;
  align-items: center;
  width: 100%;
}

.fieldsContainer {
  display: flex;
  height: 200px;
  flex-direction: column;
  flex-wrap: wrap;
}

.buttonSpace {
  margin-top: 8px;
}

.answerWrapper {
  margin-bottom: 30px;
}

.previewContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 35px;
  background-color: #EDEDED;
  box-sizing: border-box;
  width: 100%;

  .previewQuestion {
    width: 100%;
    padding-right: 30px;
  }

  .previewAnswer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 12px 25px;
    min-height: 44px;
    min-width: 77px;
    box-sizing: border-box;
  }
}

.additionalSettings {
  display: flex;
  margin-bottom: 25px;
}

.header {
  &_wrap {
    height: 71px;
    background-color: #9a9a9a;
    padding: 0 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 1024px;
    box-sizing: border-box;
  }

  &_menu {
    &_wrap {
      display: flex;
    }

    &_navbar {
      display: flex;
    }
  }

  &_logo {
    height: 25px;
    width: 157px;
    background-image: url(../../img/logo.svg);

    &_wrap {
      margin-right: 50px;
    }
  }

  &_link {
    display: flex;
    align-items: center;
    margin-right: 100px;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.165px;
    color: #000000;
    opacity: 0.4;
    text-decoration: none;
      &_active {
          opacity: 1;
      }
  }

  &_logoutButton {
    background-color: #9A9A9A;
    width: 54px;
    height: 22px;
    font-family: Grotesk, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    display: flex;
    align-items: center;
    padding: 0;
    letter-spacing: -0.408px;
    border: none;
  }
}

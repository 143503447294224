.title {
  font-size: 18px;
  margin: 7px 0;
}

.questionWrapper {
  margin-bottom: 30px;
}

.answer {
  display: flex;
  align-items: center;
  width: 100%;
}

.audioContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 10px;

  @media screen and (max-width: 1300px) {
   margin-bottom: 20px;
  }
}

.previewContainer {
  display: grid;
  align-items: center;
  grid-template-columns: 290px 1fr;
  padding: 25px 35px;
  background-color: #EDEDED;
  box-sizing: border-box;
  width: 100%;

  @media screen and (max-width: 1300px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.ratingContainer {
  margin-bottom: 30px;
}

.answerContainer {
  margin-bottom: 30px;
}

.solutionQuestion {
  display: flex;
  flex-wrap: wrap;
  line-height: 20px;
  width: 100%;
}

.preview {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 30px;
  height: 100%;
}

.questionContainer {
  display: flex;
  align-items: center;
}

.questionTitleContainer {
  display: flex;
  align-items: center;
  width: 70%;
  justify-content: space-between;
}

.solutionAnswer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  padding: 14px;
  min-height: 50px;
  min-width: 140px;
  box-sizing: border-box;
  background-color: #fff;
}

.pageContainer {
  max-width: 1270px;
  width: 100%;
  margin: 0 auto;
  padding: 30px;
  box-sizing: border-box;

  .breadcrumbs {
    margin-bottom: 50px;
    .breadcrumbsItem {
      font-size: 17px;
      color: #AAA;
      text-decoration: none;
    }

    .breadcrumbsCurrentItem {
      font-size: 20px;
      font-weight: 600;
    }
  }

  .categoriesContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 35px 30px;

    .categoryItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 25px;
      padding-right: 15px;
      box-sizing: border-box;
      height: 58px;
      width: 100%;
      text-decoration: none;
      background-color: #E6E6E6;

      .categoryItemText {
        font-size: 16px;
        line-height: 58px;
        color: #323232;
      }

      .wordsContainer {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

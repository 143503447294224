.pageContainer {
  display: flex;
  width: 100%;
  height: calc(100vh - 71px);
}

.contentContainer {
  width: 100%;
  height: 100%;
  padding: 28px 64px;
  box-sizing: border-box;
  overflow-y: scroll;
}

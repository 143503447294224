.formTitle {
  font-size: 18px;
}

.formContainer {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.searchButton {
  height: 50px;
  width: 200px;
  margin-left: 40px;
  background-color: #272727;
  color: #FFFFFF;
  font-size: 18px;
  border: none;
  border-radius: 0;
}

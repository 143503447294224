// @mixin hover($_property, $_toValue, $_time) {
//   transition: #{$_property} #{$_time}s;

//   &:hover {
//     #{$_property}: $_toValue;
//   }
// }

@import './variables';

@mixin button {
  margin-left: 3px;
  min-width: 50px;
  width: 50px;
  height: 50px;
  padding: 0;
  border: none;
  outline: none;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin error {
  color: $red-color;
}

@mixin inputError {
  border-color: $red-color;
}

@mixin success {
  color: $green-color;
}

.modalHeader {
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  background-color: #272727;
  padding: 0 25px;
  margin-bottom: 20px;
  box-shadow: 0 3px 15px rgba(41, 85, 97, 0.1);

  .path {
    color: #fff;
    text-transform: capitalize;
  }

  .id {
    color: #fff;
  }
}

.contentContainer {
  padding: 25px;
  box-sizing: border-box;
}

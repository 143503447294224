@import '../../styles/variables';

.questions {
  max-width: 1270px;
  margin: 93px auto 0;

  &__headers {
    display: flex;
    margin-bottom: 12px;
    padding-left: 65px;
    font-weight: bold;
    line-height: 19px;
  }

  &__title {
    max-width: 200px;
    width: 100%;
  }

  &__item:nth-child(odd) {
    background-color: $secondary-color;
  }
}

.pagination {
  &__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    width: 38px;
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;
    color: #000000;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border: 1px solid #000000;
    box-sizing: border-box;
    margin: 0 5px;
    cursor: pointer;
    text-decoration: none;
    color: #000000;

    &_active {
      background-color: #000000;
      & > a {
        color: #ffffff;
      }
    }

    &_disabled {
      & > a {
        color: $grey-color;
      }
      color: $grey-color;
    }

  }
}

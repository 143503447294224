@import '../../styles/variables';

.saveButton {
  display: block;
  width: 233px;
  height: 58px;
  margin-left: auto;
  background-color: $primary-color;
  outline: none;
  border: none;
  color: $white-color;
  font-family: Grotesk, Arial, Helvetica, sans-serif;
  font-size: 18px;
  margin-top: 15px;
  padding: 0;
}

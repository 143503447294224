@import '../../styles/mixins';
@import '../../styles/variables';

.addButton {
  @include button;
  background-image: url(../../img/icons/add-button.svg);
  background-color: $primary-color;

  &:disabled {
    background-image: url(../../img/icons/add-button-disabled.svg);
    background-color: $secondary-color;
  }
}
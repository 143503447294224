.container {
  position: relative;
  width: 100%;

  .formError {
    position: absolute;
    top: 23px;
    right: 7px;
  }
}

.input {
  background-color: #F2F2F2;
  min-width: 200px;
  margin: 9px 0;
  outline: none;
  height: 48px;
  font-size: 18px;
  width: 100%;
  border: 1px solid #000;
  box-sizing: border-box;
  padding: 0 30px 0 15px;
}

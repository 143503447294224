.pageContainer {
  padding: 30px 50px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.filterContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto 50px auto;
  max-width: 1200px;
  width: 100%;

  .inputsContainer {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
  }
}

.dataContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0 40px;
  height: 100%;
  margin: 0 auto;
  max-width: 1200px;
  box-sizing: border-box;

  .dataContainerHeader {
    width: 100%;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    background-color: #E6E6E6;
  }

  .dataContainerTitle {
    font-size: 17px;
    text-transform: capitalize;
  }

  .dataContainerCount {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    border-radius: 20px;
    color: #fff;
    font-size: 17px;
    height: 41px;
    width: 105px;
  }

  .dataItemsContainer {
    border-right: 1px solid #C6C6C6;
    border-left: 1px solid #C6C6C6;
  }

  .dataItem {
    width: 100%;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    background-color: #F7F7F7;
    border-bottom: 1px solid #C6C6C6;
  }

  .dataItemTitle {
    font-size: 15px;
    text-transform: uppercase;
  }
}

.errorContainer {
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .errorTitle {
    font-size: 45px;
    margin-bottom: 100px;
  }
}

.showButton {
  width: 257px;
  height: 63px;
  border: none;
  outline: none;
  background-color: #272727;
  text-transform: uppercase;
  color: #fff;
  font-size: 24px;
}

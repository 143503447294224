@import '../../styles/variables';

.questions {
  max-width: 1600px;
  margin: 93px auto 0;

  &__headers {
    display: flex;
    margin-bottom: 12px;
    padding-left: 25px;
    font-weight: bold;
    line-height: 19px;
  }

  &__title {
    max-width: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      margin-bottom: 8px;
    }
  }

  &__item:nth-child(odd) {
    background-color: $secondary-color;
  }
}

.question {
  &__info {
    display: flex;
    cursor: pointer;
  }

  &__infoItem {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 250px;
    width: 100%;
    height: 58px;
    word-break: break-word;
  }

  &__infoItem:first-child {
    margin-left: 25px;
  }

  &__approveButton,
  &__notApproveButton {
    width: 29px;
    height: 29px;
    padding: 0;
    border: none;
    background: none;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.8;
    transition: opacity $transition-duration;

    &:hover {
      opacity: 1;
    }

    &:disabled {
      opacity: 0.3;
    }
  }

  &__approveButton {
    margin-right: 12px;
    background-image: url(../../img/icons/approve-button.svg);
  }

  &__notApproveButton {
    background-image: url(../../img/icons/not-approve-button.svg);
  }
}

.pagination {
  &__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    width: 38px;
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;
    color: #000000;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border: 1px solid #000000;
    box-sizing: border-box;
    margin: 0 5px;
    cursor: pointer;
    text-decoration: none;
    color: #000000;

    &_active {
      background-color: #000000;
      & > a {
        color: #ffffff;
      }
    }

    &_disabled {
      & > a {
        color: $grey-color;
      }
      color: $grey-color;
    }

  }
}

.input {
  height: 35px;
  width: calc(100% - 10px);
  border: 1px solid #CBCBCB;
  box-sizing: border-box;
  padding: 0 15px;
}

.button {
  height: 35px;
  background-color: #272727;
  border: none;
  color: #FFF;
}

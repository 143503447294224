.answerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}

.questionTitleContainer {
  display: flex;
  align-items: center;
  width: 70%;
  justify-content: space-between;
}

.checkboxContainer {
  display: flex;
}

.title {
  font-size: 18px;
  margin: 7px 0;
}

.questionWrapper {
  margin-bottom: 30px;
}

.answer {
  display: flex;
  align-items: center;
  width: 100%;
}

.fieldsContainer {
  display: flex;
  height: 200px;
  flex-direction: column;
  flex-wrap: wrap;
}

.answerWrapper {
  margin-bottom: 30px;
}

.videoContainer {
  display: flex;
  align-items: center;
  height: 100%;
  border-radius: 10px;

  @media screen and (max-width: 1300px) {
    margin-bottom: 20px;
  }
}

.previewContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 35px;
  background-color: #EDEDED;
  box-sizing: border-box;
  width: 100%;

  @media screen and (max-width: 1300px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .previewAnswer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 12px 25px;
    min-height: 44px;
    min-width: 77px;
    box-sizing: border-box;
  }
}

.ratingContainer {
  margin-bottom: 30px;
}

.preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}

.previewAnswerWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.questionContainer {
  display: flex;
  align-items: center;
}

.questionTitleContainer {
  display: flex;
  align-items: center;
  width: 70%;
  justify-content: space-between;
}

.checkboxContainer {
  display: flex;
}

.solutionContainer {
  display: flex;
  align-items: center;
}

.solutionAnswer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 14px;
  min-height: 50px;
  min-width: 50px;
  box-sizing: border-box;
  background-color: #fff;
}

@import '../../styles/variables';
@import '../../styles/mixins';

.textInput {
  width: 100%;
  height: 48px;
  padding: 15px 20px;
  font-size: 18px;
  background-color: $secondary-color;
  border: 1px solid $primary-color;
  box-sizing: border-box;
  
  &__white {
    background-color: $white-color;
  }
}


.error {
  @include inputError;
}

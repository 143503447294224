.dndContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #BBB;
  background-color: #EDEDED;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  height: 175px;
  width: 500px;
  cursor: pointer;

  .dndTitle {
    margin-bottom: 15px;
    font-size: 18px;
    color: #BBB;
  }
}

@import '../../styles/mixins';

.deleteButton {
  @include button;
  background-image: url(../../img/icons/delete-button.svg);
  background-color: transparent;

  &:disabled {
    background-image: url(../../img/icons/delete-button-disabled.svg);
  }
}

.pageContainer {
  max-width: 1270px;
  width: 100%;
  margin: 0 auto;
  padding: 30px;
  box-sizing: border-box;

  .pageHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .breadcrumbs {
      margin-bottom: 50px;

      .breadcrumbsItem {
        font-size: 17px;
        color: #AAA;
        text-decoration: none;
      }

      .breadcrumbsCurrentItem {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .actionContainer {
      display: flex;
      align-items: center;

      .searchContainer {
        display: flex;
        align-items: center;

        .searchInputContainer {
          position: relative;

          .trashIcon {
            position: absolute;
            right: 10px;
            top: 12px;
            cursor: pointer;
          }

          .searchInput {
            background-color: #FFF;
            border: 1px solid #CBCBCB;
            box-sizing: border-box;
            height: 40px;
            width: 250px;
            padding: 0 30px 0 20px;
          }
        }

        .searchButton {
          height: 40px;
          width: 100px;
          background-color: #0F0F0F;
          font-size: 18px;
          color: #FFF;
          border: none;
          margin-left: 6px;
          box-sizing: border-box;
        }
      }

      .navigationButton {
        height: 40px;
        width: 150px;
        background-color: #3080DE;
        font-size: 18px;
        color: #FFF;
        border: none;
        margin-left: 25px;
        box-sizing: border-box;
      }
    }
  }

  .wordsTableContainer {
    margin-top: 25px;

    .createWordContainer {
      display: grid;
      grid-template-columns: 150px 1.2fr 2fr 100px 200px 120px;
      align-items: center;
      height: 58px;
      padding: 0 20px;

      .uploadPreview {
        display: flex;
        align-items: center;
      }

      .sound {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        border-radius: 16px;
        border: none;
        background-color: #2962D1;
        cursor: pointer;
      }

      .upload {
        display: flex;
        align-items: center;
        height: 38px;
        width: 150px;
        background-color: #3237BC;
        padding-left: 15px;
        border: none;
        cursor: pointer;

        .uploadTitle {
          display: flex;
          flex-direction: column;
          margin-left: 15px;

          span {
            &:first-child {
              font-size: 13px;
              font-weight: bold;
              color: #fff;
            }

            &:last-child {
              font-size: 9px;
              color: #ffffff90;
              margin-top: 2px;
            }
          }
        }
      }

      .save {
        display: flex;
        align-items: center;
        height: 38px;
        width: 125px;
        padding: 0;
        font-size: 14px;
        color: #000;
        font-weight: bold;
        border: none;
        background-color: transparent;
      }

      .input {
        height: 38px;
        width: calc(100% - 30px);
        border: 1px solid #CBCBCB;
        box-sizing: border-box;
        padding: 0 15px;
      }

      .imgPreview {
        height: 48px;
        width: 48px;
        box-sizing: border-box;
        border: 1px solid #D3D3D3;
      }

      .actionBtn {
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 5px;
        }
      }

      .description {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding-right: 30px;
      }
    }

    .wordsTableHeader {
      display: grid;
      grid-template-columns: 150px 1.2fr 2fr 100px 200px 120px;
      padding: 0 20px 15px 20px;
      border-bottom: 2px solid #A8A8A8;

      .wordsTableHeaderTitle {
        font-weight: 600;
      }
    }
  }
}

.uploadInput {
  visibility: hidden;
  height: 0;
  width: 0;
}

.imgName {
  color: #002FD5;
  font-size: 14px;
  text-decoration: underline;
  margin-left: 10px;
  font-weight: bold;
  text-transform: lowercase;
}

.paginationContent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  color: #AAA;
  font-size: 16px;
}

.paginationButton {
  height: 25px;
  width: 25px;
  display: flex;
  margin: 0 1px;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.paginationNavigationButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.paginationDisabledButton {
  display: none;
}

.paginationActiveButton {
  background-color: #2962D1;
  color: #FFF;
}

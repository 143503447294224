@import 'src/styles/variables';

.main {
  display: flex;
  min-height: calc(100% - 71px);
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.words {
  margin-bottom: 38px;
}

.words__wrapper {
  display: flex;
}

.words__header,
.preview__header {
  margin-bottom: 18px;
}

.words__title,
.preview__title {
  font-size: 18px;
}

.words {
  &__list {
    width: 100%;
    max-width: 500px;
  }

  &__item {
    display: flex;
  }

  &__item:not(:last-child) {
    margin-bottom: 18px;
  }
}

.preview {
  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: 26px 34px;
    background-color: $secondary-color;
  }

  &__item {
    display: flex;
    align-items: center;
    min-width: 55px;
    height: 55px;
    padding: 0 20px;
    margin: 3px 0;
    font-size: 18px;
    box-sizing: border-box;
    background-color: white;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.additionalSettings {
  display: flex;
}

.formContainer {
  display: flex;
  min-height: calc(100% - 71px);
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.exercisesWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-left: 1px solid #000000;
  max-width: 1100px;
  margin-bottom: 70px;
}

.exerciseContainer {
  width: 100%;
  background-color: #F2F2F2;
  border: 1px solid #000000;
  border-left: none;
  box-sizing: border-box;
}

.emptyContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border: 1px dashed #000000;
  border-left: none;
  box-sizing: border-box;
}

.fieldContainer {
  display: flex;
  align-items: center;
}

.questionContainer {
  display: flex;
  align-items: center;
  background-color: #B7B7B7;
  padding: 15px;
  border-bottom: 1px solid #000000;
  box-sizing: border-box;
}

.answerContainer {
  background-color: #F2F2F2;
  padding: 15px;
  box-sizing: border-box;
}

.title {
  font-size: 18px;
  margin-bottom: 7px;
}

.ratingContainer {
  margin-bottom: 50px;
}

.addBtnContainer {
  margin: 4px 0;
}

.additionalSettings {
  display: flex;
}

@import '../../styles/variables';
@import '../../styles/mixins';

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 876px;
  height: 407px;
  padding: 30px 40px;
  background-color: $white-color;
  box-sizing: border-box;

  &__background {
    position: fixed;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
  
  &__title {
    margin-bottom: 100px;
    font-size: 24px;
  }
  
  &__text {
    width: 525px;
    margin: 0 auto 74px;
    font-size: 24px;
    text-align: center;
  }
  
  &__controls {
    display: flex;
    justify-content: space-between;
    width: 700px;
    margin: 0 auto;
  }
 
  &__cancel, &__confirm {
    width: 257px;
    height: 63px;
    border: none;
    outline: none;
    background-color: $light-grey-color;
    font-size: 24px;
  }
 
  &__confirm {
    background-color: $primary-color;
    color: $white-color;
  }
 
  &__closeButton {
    position: absolute;
    right: 25px;
    top: 24px;
    width: 26px;
    height: 26px;
    border: none;
    outline: none;
    background: transparent;
    background-image: url(../../img/icons/close-button.svg);
    background-position: center;
    background-repeat: no-repeat;
  }

  &__error {
    @include error;
  }

  &__success {
    @include success;
  }
}
@import '../../styles/variables';

.form {
  display: flex;
  flex-direction: column;
  
  &__email,
  &__password {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  &__headerNormal,
  &__email,
  &__password,
  &__rememberMe {
    line-height: 21px;
    color: $dark-grey-color;
  }

  &__headerBold {
    font-size: 30px;
    line-height: 36px;
    font-weight: bold;
  }

  &__header {
    margin-bottom: 23px;
  }

  &__input {
    width: 350px;
    height: 50px;
    margin-top: 10px;
    padding: 15px 20px;
    font-family: Grotesk, Arial, Helvetica, sans-serif;
    font-size: 14px;
    background-color: $secondary-color;
    border: 1px solid $light-grey-color;
    border-radius: 5px;
    box-sizing: border-box;
  }

  &__rememberMe {
    display: flex;
    align-items: center;
    width: 126px;
    cursor: pointer;
  }

  &__checkbox[type="checkbox"] {
    display: none;

    &:checked ~.form__customCheckbox::after {
      background-color: $dark-grey-color;
    }
  }

  &__customCheckbox {
    position: relative;
    display: block;
    width: 15px;
    height: 15px;
    margin-left: 5px;
    border: 1px solid $light-grey-color;

    &:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      transition: all linear $transition-duration;
    }
  }

  &__button {
    height: 50px;
    margin-top: 10px;
    padding: 0;
    font-family: Grotesk, Arial, Helvetica, sans-serif;
    line-height: 19px;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    background-color: $primary-color;
    color: $white-color;
  }
}
.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    font-size: 24px;
  }

  .closeIcon {
    cursor: pointer;
  }
}

.description {
  margin: 0 auto;
  text-align: center;
  width: 525px;
  font-size: 24px;
  line-height: 36px;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  button {
    height: 63px;
    width: 257px;
    border: none;
    font-size: 24px;
  }
}

.formContainer {
  display: flex;
  min-height: calc(100% - 71px);
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.answerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}

.title {
  font-size: 18px;
  margin-bottom: 7px;
}
.questionWrapper {
  margin-bottom: 30px;
}

.answer {
  display: flex;
  align-items: center;
  width: 100%;
}

.fieldsContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.answerWrapper {
  margin-bottom: 30px;
}

.previewContainer {
  display: flex;
  min-height: 150px;
  padding: 25px 35px;
  background-color: #EDEDED;
  box-sizing: border-box;
  width: 100%;

  .previewQuestion {
    width: 100%;
    padding-right: 30px;
  }

  .previewAnswer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 12px 0;
    box-sizing: border-box;
  }
}

.question {
  padding: 0 15px;
  font-size: 16px;
}

.answerItem {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  height: 45px;
  width: 45px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 3px 15px rgba(41, 85, 97, 0.1);

  &:not(:last-child) {
    margin-right: 5px;
  }
}

.questionContainer {
  display: flex;
  align-items: center;
}

.questionTitleContainer {
  display: flex;
  align-items: center;
  width: 70%;
  justify-content: space-between;
}

.checkboxContainer {
  display: flex;
}

.additionalSettings {
  display: flex;
  margin-bottom: 25px;
}

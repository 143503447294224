@import 'src/styles/variables';

.questions,
.answers {
  margin-bottom: 38px;
}

.questions__wrapper,
.answers__wrapper {
  display: flex;
}

.questions__header,
.answers__header,
.preview__header {
  margin-bottom: 18px;
}

.questions__title,
.answers__title,
.preview__title {
  font-size: 18px;
}

.questions {
  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    margin-right: 138px;
  }

  &__list {
    width: 100%;
    max-width: 378px;
  }

  &__item:not(:last-child) {
    margin-bottom: 18px;
  }

  &__answerInBeginning {
    margin-right: 37px;
  }
}

.answers {
  margin-bottom: 110px;

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 31px;
    row-gap: 18px;
    width: 100%;
    max-width: 940px;
  }

  &__item {
    display: flex;
  }

  &__checkboxLabel {
    display: flex;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
  }

  &__checkboxInput {
    display: none;
  }

  &__checkboxText {
    align-self: flex-end;
    margin-left: 11px;
  }
}

.preview {
  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: 26px 34px;
    background-color: $secondary-color;
  }

  &__item {
    margin: 5px 10px;
  }

  &__answer,
  &__question {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 55px;
    height: 55px;
    padding: 0 20px;
    font-size: 18px;
    box-sizing: border-box;
    background-color: white;
  }

  &__question {
    color: white;
    background-color: $primary-color;
  }
}

.textInput {
  width: 100px;
  height: 48px;
  padding: 15px 20px;
  font-size: 18px;
  background-color: $secondary-color;
  border: 1px solid $primary-color;
  box-sizing: border-box;
}

.additionalSettings {
  display: flex;
}

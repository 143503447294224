@import '../../styles/variables';

.aside {
  min-width: 320px;
  height: 100%;
  padding: 70px 30px;
  background-color: $light-grey-color;
  box-sizing: border-box;

  &__showButton {
    width: 100%;
    height: 62px;
    margin-top: 60px;
    padding: 0;
    border: none;
    outline: none;
    background-color: $primary-color;
    color: $white-color;
    font-family: Grotesk, Arial, Helvetica, sans-serif;
    font-size: 18px;
  }
}

@import 'src/styles/variables';

.questions {
  margin-bottom: 38px;
}

.correctAnswers {
  margin-bottom: 78px;
  max-width: 378px;
}

.questions__wrapper {
  display: flex;
}

.questions__header,
.preview__header,
.correctAnswers__header {
  margin-bottom: 18px;
}

.questions__title,
.preview__title,
.correctAnswers__title {
  font-size: 18px;
}

.questions {
  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    margin-right: 138px;
  }

  &__list {
    width: 100%;
    max-width: 378px;
  }

  &__item {
    display: flex;
  }

  &__item:not(:last-child) {
    margin-bottom: 18px;
  }

  &__itemWrongWord {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    margin-right: 57px;
    font-size: 18px;
  }

  &__answerInBeginning {
    margin-right: 37px;
  }
}

.answers {
  &__checkboxLabel {
    display: flex;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
  }

  &__checkboxInput {
    display: none;
  }

  &__checkboxText {
    align-self: flex-end;
    margin-left: 11px;
  }
}

.correctAnswers__item:not(:last-child) {
  margin-bottom: 18px;
}

.preview {
  margin-top: 24px;

  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: 26px 34px;
    background-color: $secondary-color;
  }

  &__item {
    margin: 5px 10px;
  }

  &__answer,
  &__question {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 55px;
    height: 55px;
    padding: 0 20px;
    font-size: 18px;
    box-sizing: border-box;
    background-color: white;
  }

  &__question {
    color: white;
    background-color: $primary-color;
  }
}

.additionalLettersInput {
  height: 48px;
  width: 378px;
  border: 1px solid black;
  background-color: #F2F2F2;
  box-sizing: border-box;
  padding: 15px 20px;
  font-size: 18px;
}

.errorMessage {
  margin-top: 8px;
  color: red;
}

.additionalSettings {
  display: flex;
  margin-top: 25px;
}

$primary-color: #272727;
$secondary-color: #F2F2F2;
$light-grey-color: #C4C4C4;
$grey-color: #B7B7B7;
$medium-grey-color: #9A9A9A;
$dark-grey-color:#4A5568;;
$white-color: #fff;
$red-color: #ff0000;
$green-color: #228B22;
$background-color: #E9E9E9;

$transition-duration: 300ms;

.pageContainer {
  max-width: 1270px;
  width: 100%;
  margin: 0 auto;
  padding: 30px;
  box-sizing: border-box;

  .pageTitle {
    font-size: 20px;
    margin-bottom: 50px;
    font-weight: 600;
  }

  .levelsTable {
    display: flex;
    flex-direction: column;
    width: 100%;

    .levelsTableHeader {
      display: grid;
      grid-template-columns: repeat(3, 1fr) 100px;
      padding-left: 80px;
      padding-right: 20px;
      margin-bottom: 30px;

      .levelsTableHeaderTitle {
        font-weight: 600;
      }
    }

    .levelsTableBodyItem {
      display: grid;
      grid-template-columns: repeat(3, 1fr) 100px;
      padding-left: 80px;
      padding-right: 20px;
      height: 58px;
      cursor: pointer;
      text-decoration: none;

      .levelsTableText {
        font-size: 16px;
        line-height: 58px;
        color: #323232;
      }

      .icon {
        margin: auto;
      }
    }
  }
}



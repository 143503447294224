@import "../../styles/variables";

.select {
  position: relative;

  &__dropdown {
    position: relative;
    padding: 20px 25px;
    font-size: 18px;
    font-weight: 600;
    background-color: $secondary-color;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 25px;
      width: 16px;
      height: 100%;
      background: url("../../img/icons/arrow-down.svg") no-repeat;
      background-position: center center;
      transform: rotate(0);
      transition: transform $transition-duration;
    }

    &_active {
      &::before {
        transform: rotate(180deg);
      }
    }
  }

  &__dropdownList {
    position: absolute;
    width: 100%;
    max-height: 350px;
    box-sizing: border-box;
    font-size: 15px;
    background-color: $white-color;
    z-index: 2;
    opacity: 0;
    transition: opacity $transition-duration;
    pointer-events: none;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: $light-grey-color;
      height: 20px;
    }

    &_active {
      opacity: 1;
      pointer-events: initial;
    }
  }

  &__dropdownItem {
    display: flex;
    align-items: center;
    padding: 18px 25px;
    transition: background-color $transition-duration;
    cursor: pointer;

    &:hover {
      background-color: $secondary-color;
    }

    &_active {
      font-weight: 700;
      background-color: $secondary-color;
    }
  }
}

.select:not(:last-child) {
  margin-bottom: 20px;
}
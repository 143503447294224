@import "./styles/fonts";
@import "./styles/reset";
@import "./styles/variables";
@import "./styles/mixins";

html {
  font-family: Grotesk, Arial, Helvetica, sans-serif;
  color: $primary-color;
}

.container {
  height: 100vh;
  overflow: hidden;
}

.css-f5brtt-BaseToolTop {
  width: 350px;
}
